import { Appointment, PatientAddress } from '@revival/scheduler';

export const addNewPatientAddress = async (
  patientId: string,
  address: Partial<PatientAddress>,
  token: string | null,
) => {
  const url = `${process.env.REACT_APP_REVIVAL_API_URL}/v1/patient/${patientId}/address/new`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(address),
    });
    const data = await response.json();
    return {
      status: response.status,
      ...data,
    };
  } catch (error) {
    console.error('ERROR::addNewPatientAddress: ', error);
    return { success: false, data: null };
  }
};

export const createAppointment = async (
  appointment: Partial<Appointment>,
  token: string | null,
) => {
  const url = `${process.env.REACT_APP_REVIVAL_API_URL}/v1/appointment/new`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(appointment),
    });
    const data = await response.json();
    return {
      status: response.status,
      ...data,
    };
  } catch (error) {
    console.error('ERROR::createAppointment: ', error);
    return { success: false, data: null };
  }
};

export const getCalendarConfig = async (
  facilityId: string,
  token: string | null,
) => {
  const url = `${process.env.REACT_APP_REVIVAL_API_URL}/v1/calendar/${facilityId}/config`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return {
      status: response.status,
      ...data,
    };
  } catch (error) {
    console.error('ERROR::getCalendarConfig: ', error);
    return { success: false, data: null };
  }
};

export const getAvailableAppointmentSlots = async (
  date: string,
  addressId: string,
  facilityId: string,
  token: string | null,
) => {
  const url = `${process.env.REACT_APP_REVIVAL_API_URL}/v1/appointment-slots/${facilityId}?date=${date}&address_id=${addressId}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return {
      status: response.status,
      ...data,
    };
  } catch (error) {
    console.error('ERROR::getCalendarConfig: ', error);
    return { success: false, data: null };
  }
};
