import { Box, Container, Text, VStack } from '@chakra-ui/react';
import { Logo } from '../components/Logo';
import React from 'react';
import { FaCalendarCheck } from 'react-icons/fa';

export const ConfirmationPage = () => {
  return (
    <Container maxW={{ md: '100vw', xl: '75vw' }}>
      <Box
        w={{ base: '50vw', lg: '25vw' }}
        h={{ base: '40vw', lg: '20vw' }}
        m={'0 auto'}
        maxW={'360px'}
        maxH={'360px'}
        mt={-10}
      >
        <Logo />
      </Box>

      <VStack alignItems={'center'} gap={'2em'}>
        <FaCalendarCheck size={100} color={'green'} />
        <Text fontWeight={'bold'} fontSize={'17px'}>
          Appointment successfully scheduled!
        </Text>
        <Text justifyContent={'center'} textAlign={'center'}>
          If you need to make changes to your appointment, please call us at
          (602) 780-1463
        </Text>
      </VStack>
    </Container>
  );
};
