import { Box, Card, CardBody, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FaMapLocationDot } from 'react-icons/fa6';
import { AddNewAddressButton } from './AddNewAddressButton';
import { useSchedulerStore } from '../../stores/SchedulerStore';

export const SelectAddressPanel = () => {
  const reminder = useSchedulerStore((state) => state.reminder);
  const setSelectedAddress = useSchedulerStore(
    (state) => state.setSelectedAddress,
  );
  return (
    <Card
      rounded={0}
      padding={0}
      // minW={'600px'}
      maxW={'500px'}
      m={'0 auto auto auto'}
    >
      <CardBody p={5} w={'100%'}>
        <VStack gap={{ base: '10px', lg: '25px' }} w={'100%'}>
          <Text fontSize={'lg'} fontWeight={'bold'} pl={2}>
            Select Your Appointment Address
          </Text>

          {reminder &&
            reminder.patient.addresses?.map((address, index) => (
              <Box key={`address.${index}`} w={{ base: '90%', lg: '75%' }}>
                <Box
                  w={'100%'}
                  pl={2}
                  py={2}
                  border={'1px solid'}
                  borderColor={'var(--chakra-colors-chakra-border-color)'}
                  borderRadius={5}
                  onClick={() => setSelectedAddress(address)}
                  cursor={'pointer'}
                >
                  <HStack>
                    <Box mx={3}>
                      <FaMapLocationDot
                        size={30}
                        color={'var(--chakra-colors-messenger-500)'}
                      />
                    </Box>
                    <VStack
                      w={'100%'}
                      gap={0}
                      alignItems={'left'}
                      flex={1}
                      userSelect={'none'}
                    >
                      <Text>{address.label}</Text>
                      <Text>{address.street}</Text>
                      <Text>{`${address.city}, ${address.state} ${address.zip}`}</Text>
                    </VStack>
                  </HStack>
                </Box>
              </Box>
            ))}
          <AddNewAddressButton />
        </VStack>
      </CardBody>
    </Card>
  );
};
