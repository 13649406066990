import { Avatar, Box, Flex, Text, VStack } from '@chakra-ui/react';
import { MdAccessTime } from 'react-icons/md';
import { GiHealthCapsule } from 'react-icons/gi';
import React from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { AppointmentReminder, PatientAddress } from '@revival/scheduler';
import { isMobile } from 'react-device-detect';

interface AppointmentInfoProps {
  reminder: AppointmentReminder;
  selectedAddress: PatientAddress;
}

export const AppointmentInfo = ({
  reminder,
  selectedAddress,
}: AppointmentInfoProps) => {
  const name = `${reminder.patient.first_name} ${reminder.patient.last_name}`;
  return (
    <VStack
      p={5}
      pt={0}
      w={{ base: '100%', lg: '300px' }}
      alignItems={'start'}
      h={'100%'}
    >
      {!isMobile && <Avatar name={name} size={'lg'} />}
      <Text fontSize={'xl'} fontWeight={'bold'}>
        {name}
      </Text>
      <Flex>
        <Box pt={'4px'} pr={'5px'}>
          <MdAccessTime />
        </Box>
        <Text>{`${reminder.duration} mins`}</Text>
      </Flex>
      <Flex>
        <Box pt={'4px'} pr={'5px'}>
          <GiHealthCapsule />
        </Box>
        <Text>{reminder.reason}</Text>
      </Flex>
      <Flex>
        <Box pt={'4px'} pr={'3px'}>
          <FaLocationDot />
        </Box>
        <VStack>
          <Text w={'100%'}>{selectedAddress.street}</Text>
          <Text
            w={'100%'}
          >{`${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.zip}`}</Text>
        </VStack>
      </Flex>
    </VStack>
  );
};
