import './logo.css';
import { Box } from '@chakra-ui/react';
export const Logo = () => {
  return (
    <Box
      as={'svg'}
      className="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <g>
        <g>
          <path
            className="logo"
            d="M101.9,633.35h14.62v72.58h-13.41V652.7l-27.52,32.76L47.96,652.7v53.23H34.66v-72.58h14.52l26.41,31.45L101.9,633.35z"
          />
          <path
            className="logo"
            d="M195.14,646.65h-53.33v16.33h42.95v13.31h-42.95v16.33h53.33v13.31H128.4v-72.58h66.74V646.65z"
          />
          <path
            className="logo"
            d="M264.59,633.35h13.41v72.58h-14.52l-44.76-53.23v53.23h-13.31v-72.58h14.52l44.66,53.22V633.35z"
          />
          <path
            className="logo"
            d="M302.78,651.79h-13.21v-18.35h13.21V651.79z"
          />
          <path
            d="M383.93,652.9h-13.41v-5.54c0-0.4-0.3-0.71-0.6-0.71h-44.56c-0.4,0-0.71,0.3-0.71,0.71v14.92c0,0.41,0.3,0.71,0.71,0.71
			h44.56c7.66,0,14.01,6.25,14.01,14.02v14.92c0,7.77-6.35,14.02-14.01,14.02h-44.56c-7.76,0-14.01-6.25-14.01-14.02v-5.54h13.31
			v5.54c0,0.41,0.3,0.71,0.71,0.71h44.56c0.3,0,0.6-0.3,0.6-0.71V677c0-0.4-0.3-0.71-0.6-0.71h-44.56
			c-7.76,0-14.01-6.25-14.01-14.02v-14.92c0-7.76,6.25-14.01,14.01-14.01h44.56c7.66,0,14.01,6.25,14.01,14.01V652.9z"
          />
          <path
            d="M498.24,667.02c0,7.66-6.35,14.01-14.11,14.01h-3.93l18.04,21.47v3.43h-14.52l-20.97-24.9h-23.49h-0.3v24.9h-13.31v-72.48
			h58.47c7.76,0,14.11,6.35,14.11,14.01V667.02z M484.12,667.72c0.4,0,0.71-0.3,0.71-0.71v-19.56c0-0.4-0.3-0.71-0.71-0.71h-44.45
			c-0.4,0-0.71,0.3-0.71,0.71v19.56c0,0.4,0.3,0.71,0.71,0.71H484.12z"
          />
          <path
            className="logo"
            d="M575.85,646.65h-53.33v16.33h42.95v13.31h-42.95v16.33h53.33v13.31h-66.74v-72.58h66.74V646.65z"
          />
          <path
            className="logo"
            d="M664.46,633.35h15.42l-41.94,72.58h-12l-41.94-72.58h15.42L632,689.8L664.46,633.35z"
          />
          <path
            className="logo"
            d="M687.33,633.35h13.31v72.58h-13.31V633.35z"
          />
          <path
            className="logo"
            d="M787.73,633.35h15.42l-41.94,72.58h-12l-41.94-72.58h15.42l32.56,56.45L787.73,633.35z"
          />
          <path
            d="M867.06,633.35c7.76,0,14.11,6.25,14.11,14.01v58.57h-13.41v-24.4H821.9v24.4h-13.31v-58.57
			c0-7.76,6.25-14.01,14.01-14.01H867.06z M867.77,668.23v-20.87c0-0.4-0.3-0.71-0.71-0.71H822.6c-0.4,0-0.71,0.3-0.71,0.71v20.87
			H867.77z"
          />
          <path
            className="logo"
            d="M892.76,633.25h13.31v59.38h59.28v13.31h-72.58V633.25z"
          />
        </g>
        <g>
          <polygon
            points="459.23,488.63 282.6,596.87 406.32,462.89 406.01,420.18 451.06,392.59 488.59,345.68 431.19,345.16
			339.18,378.98 403.33,329.16 504.3,322.37 442.88,317.61 508.61,299.31 580.37,336.36 548.56,294.07 637.82,331.28 638.28,357.89
			616.48,368.89 531.22,353.42 582.81,376.29 494.8,422.13 582.78,456.79 658.44,528.18 686.47,601.93 643.12,547.99 614.55,522.22
			571.88,503.59 553.21,476.12 476.12,483.32 420.93,468.54 		"
          />
          <polygon points="572.84,397.2 626.99,403.71 651.63,430.6 		" />
          <polygon
            points="666.28,437.96 708.93,456.1 703.64,482.87 717.4,520.33 694.12,488.88 646.79,500.77 689.93,478.58
			694.62,457.99 		"
          />
        </g>
      </g>
    </Box>
  );
};
