import { useState, useEffect } from 'react';

interface GenericFetchData<Type> {
  data: Type | null;
  loading: boolean;
  error: Error | null;
}

function useFetchData<Type>(url: string, options: any): GenericFetchData<Type> {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Reset states when URL changes
    setData(null);
    setLoading(true);
    setError(null);

    fetch(url, {
      method: 'GET', // The method, GET in this case
      headers: {
        'Content-Type': 'application/json', // Assuming your server expects JSON
        // Include any other headers your API requires
      },
      ...options, // Pass any other options to the fetch API
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok (${response.statusText})`,
          );
        }
        return response.json(); // If the response is a JSON object
      })
      .then((data) => {
        setData(data); // Handling the data from the response
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          'There has been a problem with your fetch operation:',
          error,
        );
        setError(error);
        setLoading(false);
      });
  }, [url]); // eslint-disable-line

  return { data, loading, error };
}

export default useFetchData;
