import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import Calendar from './Calendar';
import { addMinutes, format, setHours, setMinutes } from 'date-fns';
import { FaGlobeAmericas } from 'react-icons/fa';
import { useSchedulerStore } from '../../stores/SchedulerStore';
import { createAppointment } from '../../services/revival';
import useToken from '../../hooks/useToken';

export const AppointmentCalendar = () => {
  const today = new Date();
  const { token } = useToken();
  const slotsRef = useRef(null);

  const selectedDate = useSchedulerStore((state) => state.selectedDate);
  const setSelectedDate = useSchedulerStore((state) => state.setSelectedDate);
  const appointmentSlots = useSchedulerStore(
    (state) => state.availableAppointmentSlots,
  );
  const setAppointmentSlots = useSchedulerStore(
    (state) => state.setAvailableAppointmentSlots,
  );
  const calendarConfig = useSchedulerStore((state) => state.calendarConfig);
  const selectedAddress = useSchedulerStore((state) => state.selectedAddress);
  const setDisplayConfirmation = useSchedulerStore(
    (state) => state.setDisplayConfirmation,
  );

  const handleNewAppointment = async (time: string) => {
    const response = window.confirm(`Book appointment for ${time}?`);
    if (!response) return;
    if (selectedDate) {
      // create appointment
      const [hours, mins] = time.split(' ')[0].split(':');

      let startTime = setHours(selectedDate, parseInt(hours));
      startTime = setMinutes(startTime, parseInt(mins));

      const endTime = addMinutes(startTime, 45);

      const appointment = {
        provider_id: appointmentSlots[time],
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        address_id: selectedAddress?.id,
      };
      console.log('creating appointment:', appointment);
      const result = await createAppointment(appointment, token);
      if (result.success) {
        console.log('appointment created:', result);
        // window.alert('Appointment successfully created!');
        setDisplayConfirmation(true);
      }
    }

    setAppointmentSlots({
      ...appointmentSlots,
      [time]: !appointmentSlots[time],
    });
  };

  useEffect(() => {
    if (
      slotsRef &&
      slotsRef.current &&
      selectedDate &&
      appointmentSlots &&
      Object.keys(appointmentSlots).length > 0
    ) {
      // @ts-ignore
      slotsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [appointmentSlots, selectedDate]);
  return (
    <Box
      w={{ base: '100%' }}
      p={5}
      borderLeft={'var(--chakra-colors-chakra-border-color) 1px solid'}
    >
      <Box mb={'15px'}>
        <Text fontSize={'lg'} fontWeight={'bold'} pl={2}>
          Select a Date & Time
        </Text>
      </Box>
      <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={'25px'}>
        <Box w={{ base: '100%' }}>
          <Calendar
            setSelectedDate={setSelectedDate}
            minDate={calendarConfig.startDate}
            maxDate={calendarConfig.endDate}
            disabledWeekDays={[0, 6]}
            disabledDatesList={calendarConfig.disabledDatesList}
          />

          <Flex mt={5}>
            <Box p={1}>
              <FaGlobeAmericas />
            </Box>
            <Text fontSize={'sm'}>
              {Intl.DateTimeFormat().resolvedOptions().timeZone} -{' '}
              {format(today, 'p')}
            </Text>
          </Flex>
        </Box>

        <VStack spacing={3} w={{ base: '100%', lg: '50%' }} m={'0 auto'}>
          {selectedDate && appointmentSlots && (
            <>
              <Text
                ref={slotsRef}
                textAlign={'left'}
                w={'100%'}
                h={'32px'}
                pt={1}
              >
                {format(selectedDate, 'eeee, MMMM dd')}
              </Text>

              {Object.keys(appointmentSlots).map((time) => (
                <Button
                  key={time}
                  colorScheme={appointmentSlots[time] ? 'messenger' : 'gray'}
                  onClick={() => handleNewAppointment(time)}
                  isDisabled={!appointmentSlots[time]}
                  w={'100%'}
                  size={'lg'}
                >
                  {time}
                </Button>
              ))}
            </>
          )}

          {(!selectedDate || !appointmentSlots) && (
            <Center>
              <Spinner size={'xl'} />
            </Center>
          )}
        </VStack>
      </Flex>
    </Box>
  );
};
