import { useRouteError } from 'react-router-dom';
import { Logo } from './components/Logo';
import { Box, Container, Text } from '@chakra-ui/react';
import React from 'react';

export default function ErrorPage() {
  const error: any = useRouteError();
  const message = error.statusText || error.message || '';

  return (
    <Container>
      <Logo />
      <Box textAlign={'center'} mt={-150}>
        <Text fontSize={'8xl'} fontWeight={'bold'}>
          Oops!
        </Text>
        <Text fontSize={'4xl'} textTransform={'uppercase'} fontWeight={200}>
          {message}
        </Text>
      </Box>
    </Container>
  );
}
