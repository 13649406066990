import {
  Box,
  Button,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  addDays,
  addMonths,
  endOfDay,
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { Weekday_Names_Short } from '../../utils/calendar-utils';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

interface CalendarProps {
  setSelectedDate: Function;
  minDate: Date;
  maxDate: Date;
  disabledDatesList?: string[];
  disabledWeekDays?: number[]; // 0 (Sunday) to 6 (Saturday)
}

const Calendar: React.FC<CalendarProps> = ({
  setSelectedDate,
  minDate,
  maxDate,
  disabledDatesList,
  disabledWeekDays = [],
}) => {
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(today));
  const disabledDatesSet = new Set(disabledDatesList);

  const isDateDisabled = (date: Date): boolean => {
    const formattedDate = format(date, 'yyyy-MM-dd');

    // check if date is in range
    if (isBefore(date, startOfDay(minDate)) || isAfter(date, endOfDay(maxDate)))
      return true;

    if (disabledDatesSet?.has(formattedDate)) return true;

    // check if day of the week is disabled
    return disabledWeekDays.includes(date.getDay());
  };

  const renderDays = () => {
    const days = [];
    const startDate = startOfWeek(startOfMonth(selectedMonth)); // Start from the Sunday of the first week
    const daysInMonth = getDaysInMonth(selectedMonth);
    const daysToDisplay = daysInMonth + startOfMonth(selectedMonth).getDay(); // daysInMonth + days from previous month

    for (let i = 0; i < daysToDisplay; i++) {
      const day = addDays(startDate, i);
      days.push(
        <Box
          as="button"
          w={'50px'}
          h={'50px'}
          p={2}
          m={1}
          bg={isDateDisabled(day) ? 'white' : 'blue.50'}
          color={isDateDisabled(day) ? 'gray.400' : 'blue.500'}
          fontWeight={isDateDisabled(day) ? 'normal' : 'bold'}
          onClick={() => !isDateDisabled(day) && setSelectedDate(day)}
          disabled={isDateDisabled(day)}
          key={i}
          borderRadius={'30px'}
          textAlign={'center'}
        >
          <Text fontSize={'sm'}>{format(day, 'd')}</Text>
        </Box>,
      );
    }
    return days;
  };

  const nextMonth = () => {
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const prevMonth = () => {
    setSelectedMonth(subMonths(selectedMonth, 1));
  };

  return (
    <>
      <HStack mb={'10px'}>
        <Text pl={2}>{format(selectedMonth, 'MMMM yyyy')}</Text>
        <Spacer />
        <Button size={'sm'} variant={'ghost'} onClick={() => prevMonth()}>
          <FaChevronLeft />
        </Button>
        <Button size={'sm'} variant={'ghost'} onClick={() => nextMonth()}>
          <FaChevronRight />
        </Button>
      </HStack>
      <SimpleGrid columns={7} spacing={2}>
        {Weekday_Names_Short.map((day, index) => (
          <Box
            w={'50px'}
            textAlign={'center'}
            key={`day-${index}`}
            px={2}
            mx={1}
          >
            <Text fontSize={'sm'}>{day.toUpperCase()}</Text>
          </Box>
        ))}
        {renderDays()}
      </SimpleGrid>
    </>
  );
};

export default Calendar;
