import React, { useEffect } from 'react';
import { Logo } from './components/Logo';
import { Box, Center, Container, SlideFade, Spinner } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetchData from './hooks/useFetchData';
import { AppointmentReminder } from '@revival/scheduler';
import { SelectAddressPanel } from './components/address/SelectAddressPanel';
import { AppointmentPanel } from './components/calendar/AppointmentPanel';
import { useSchedulerStore } from './stores/SchedulerStore';
import useToken from './hooks/useToken';
import { ConfirmationPage } from './pages/ConfirmationPage';

function App() {
  const { reminderId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();

  const selectedDate = useSchedulerStore((state) => state.selectedDate);
  const selectedAddress = useSchedulerStore((state) => state.selectedAddress);
  const setReminder = useSchedulerStore((state) => state.setReminder);
  const getCalendarConfig = useSchedulerStore(
    (state) => state.getCalendarConfig,
  );
  const getAvailableAppointmentSlots = useSchedulerStore(
    (state) => state.getAvailableAppointmentSlots,
  );
  const calendarConfig = useSchedulerStore((state) => state.calendarConfig);
  const setAvailableAppointmentSlots = useSchedulerStore(
    (state) => state.setAvailableAppointmentSlots,
  );
  const displayConfirmation = useSchedulerStore(
    (state) => state.displayConfirmation,
  );

  const {
    data: reminder,
    loading,
    error,
  } = useFetchData<AppointmentReminder>(
    `${process.env.REACT_APP_REVIVAL_API_URL}/v1/reminder/${reminderId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  //TODO: make facility id dynamic -- force again again
  const facilityId = `${process.env.REACT_APP_FACILITY_ID}`;

  useEffect(() => {
    const init = async () => {
      const calendarConfigResult = await getCalendarConfig(facilityId, token);
      console.log('calendar config:', calendarConfigResult);
      console.log('selected date:', selectedDate?.toISOString() || '');
    };

    if (reminder) {
      console.log('reminder:', reminder);
      setReminder(reminder);
      init();
    }

    if (error || (!loading && !reminder)) {
      navigate('/404');
    }
  }, [loading]); // eslint-disable-line

  useEffect(() => {
    setAvailableAppointmentSlots(null);
    const refreshSlots = async () => {
      const availableSlots = await getAvailableAppointmentSlots(
        selectedDate?.toISOString() || '',
        facilityId,
        token,
      );
      console.log('available slots:', availableSlots);
    };

    //TODO: REVIV-5: refactor to retrieve appointment slots from server
    if (selectedDate) {
      refreshSlots();
    }
  }, [selectedDate, selectedAddress]); // eslint-disable-line

  if (displayConfirmation) return ConfirmationPage();

  return (
    <Container maxW={{ md: '100vw', xl: '75vw' }}>
      <Box
        w={{ base: '50vw', lg: '25vw' }}
        h={{ base: '40vw', lg: '20vw' }}
        m={'0 auto'}
        maxW={'360px'}
        maxH={'360px'}
        mt={-10}
      >
        <Logo />
      </Box>

      {reminder && !selectedAddress && (
        <SlideFade
          offsetY="20px"
          in={true}
          transition={{ exit: { delay: 0 }, enter: { duration: 0.5 } }}
        >
          <SelectAddressPanel />
        </SlideFade>
      )}

      {reminder && selectedAddress && calendarConfig && (
        <SlideFade
          offsetY="20px"
          in={true}
          transition={{ exit: { delay: 0 }, enter: { duration: 0.5 } }}
        >
          <AppointmentPanel />
        </SlideFade>
      )}

      {loading && !calendarConfig && (
        <Center>
          <Spinner size={'xl'} />
        </Center>
      )}
    </Container>
  );
}

export default App;
